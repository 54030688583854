<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef" :model="searchForm">
      <div class="flex">
        <div class="flex items-center mr-4" style="flex:3;">
          <a-select show-search class="mr-1" style="width:80px;"
                    v-model="searchForm.admin_type">
            <a-select-option key="1" :value="1">优惠券</a-select-option>
            <a-select-option key="2" :value="2">财务券</a-select-option>
          </a-select>
          <div style="flex:1;">
            <a-select show-search style="flex:1;width:100%;" allowClear
                      placeholder="搜索优惠券"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="suggestCouponList"
                      @change="onCouponSelect"
                      v-model="searchForm.coupon_id">
              <a-select-option v-for="item in couponList"
                               :key="item.id"
                               :value="item.id">{{item.name}}<span style="margin-left:10px;color:#ccc;">{{item.reduce_cost}}元</span></a-select-option>
            </a-select>
          </div>
        </div>
        <div class="flex items-center mr-4" style="flex:2;">
          <span class="mr-2">串码</span>
          <div style="flex:1;">
            <a-input placeholder="使用串码精确查询" v-model="searchForm.exchange_code"></a-input>
          </div>
        </div>
        <div class="flex items-center mr-4" style="flex:3;">
          <span class="mr-2">领取日期</span>
          <a-range-picker style="flex:1;"
              v-model="received_time"
              valueFormat="YYYY-MM-DD"
            />
        </div>
        <div class="flex items-center mr-4" style="flex:3;">
          <span class="mr-2">使用日期</span>
          <a-range-picker style="flex:1;"
              v-model="use_time"
              valueFormat="YYYY-MM-DD"
            />
        </div>
        <div class="ml-4">
          <a-button class="mr-4 p-0" type="link" @click="handlerExport">导出</a-button>
          <a-button class="mr-4" @click="handlerResetForm">重置</a-button>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
        </div>
      </div>
      </a-form-model>
    </div>
    <div class="mt-2 mb-2">
      <span class="text-lg font-bold">优惠券发放记录</span>
    </div>

    <base-table
      id="id"
      :columnsData="columns"
      :rowKey="rowKey"
      :tableData="tableData"
      :getListFunc="initData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #order_no="{record}">
        <a-button v-if="record.order_mode==1" class="p-0" type="link" @click="handlerOrderDetail(record.order_no)">{{record.order_no}}</a-button>
        <template v-else>{{record.order_no}}</template>
      </template>
      <template #user_id="{text}">
        <!-- <a-button class="p-0" type="link" @click="handlerUserDetail(text)">{{text}}</a-button> -->
        <a-button class="p-0" type="link" @click="handlerUserDetail(text)">查看</a-button>
      </template>
    </base-table>

    
  </div>
</template>

<script>
import { getList, getDetailById, getRecord, exportRecord } from "@/api/activity/coupon.js"
export default {
  data() {
    return {
      wrapperCol: { span: 20 },
      labelCol: { span: 4 },

      couponList: [],

      received_time: [],
      use_time: [],

      searchForm: {
        admin_type: 1, // 普通类1 财务类2
        coupon_id: undefined,
        exchange_code: "",
        received_time_start: "",
        received_time_end: "",
        use_time_start: "",
        use_time_end: "",
        page: 1,
        page_count: 20,
      },
      tableData: [],
      total: 0,
      
      curRow: {},
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          align: "center",
          width: 70,
        },
        {
          title: "优惠券",
          dataIndex: "coupon_name",
          align: "center",
        },
        {
          title: "领取时间",
          dataIndex: "received_time",
          align: "center",
        },
        {
          title: "使用时间",
          dataIndex: "use_time",
          align: "center",
        },
        {
          title: "使用订单号",
          dataIndex: "order_no",
          align: "center",
          slots: { customRender: "order_no" },
        },
        {
          title: "使用城市",
          dataIndex: "city_name",
          align: "center",
          width: 90,
        },
        {
          title: "使用网点",
          dataIndex: "outlets_name",
          align: "center",
          width: 120,
        },
        {
          title: "使用抵扣金额",
          dataIndex: "actual_offers_amount",
          align: "center",
          width: 110,
        },
        {
          title: "领取人",
          dataIndex: "user_id",
          align: "center",
          width: 80,
          slots: { customRender: "user_id" },
        },
      ],
    }
  },
  created(){
    if (this.$route.query.coupon_id) {
      this.searchForm.coupon_id = this.$route.query.coupon_id
      this.defaultCoupon(this.$route.query.coupon_id)
    }
  },
  mounted() {
    this.handlerSearch()
  },
  methods: {
    async initData() {
      this.searchForm.received_time_start = ""
      this.searchForm.received_time_end   = ""
      this.searchForm.use_time_start = ""
      this.searchForm.use_time_end   = ""

      if (this.received_time.length == 2) {
        this.searchForm.received_time_start = this.received_time[0]
        this.searchForm.received_time_end   = this.received_time[1]
      }
      if (this.use_time.length == 2) {
        this.searchForm.use_time_start = this.use_time[0]
        this.searchForm.use_time_end   = this.use_time[1]
      }
      const params = this.searchForm

      const { data, code } = await getRecord(params)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    
    // 搜索
    handlerSearch() {
      if(this.searchForm.exchange_code){
        this.searchForm.coupon_id = undefined
        this.received_time = []
        this.use_time = []
      }

      this.searchForm.page = 1
      this.initData()
    },
    // 导出
    handlerExport(){
      const params = {
        admin_type: this.searchForm.admin_type,
        received_time_start: "",
        received_time_end:   "",
        use_time_start: "",
        use_time_end:   "",
      }

      if(this.received_time.length < 2 && this.use_time.length < 2){
        this.$message.info("领取时间、使用时间不能都为空")
        return
      }

      if (this.received_time.length == 2) {
        if(new Date(this.received_time[1]).getTime() - new Date(this.received_time[0]).getTime() > 31 * 24 * 3600 * 1000){
          this.$message.warning("领取时间跨度不能超一个月")
          return
        }
        params.received_time_start = this.received_time[0]
        params.received_time_end   = this.received_time[1]
      }
      if (this.use_time.length == 2) {
        if(new Date(this.use_time[1]).getTime() - new Date(this.use_time[0]).getTime() > 31 * 24 * 3600 * 1000){
          this.$message.warning("使用时间跨度不能超一个月")
          return
        }
        params.use_time_start = this.use_time[0]
        params.use_time_end   = this.use_time[1]
      }

      exportRecord(params)
    },
    // 重置
    handlerResetForm() {
      this.searchForm.coupon_id = undefined
      // this.searchForm.received_time_start = ""
      // this.searchForm.received_time_end = ""
      // this.searchForm.use_time_start = ""
      // this.searchForm.use_time_end = ""

      this.received_time = []
      this.use_time = []
      this.couponList = []

      this.handlerSearch()
    },

    async suggestCouponList (value) {
      const params = {
        admin_type: this.searchForm.admin_type,
        name: value,
        date_type: 0,
        page_count: 10,
      }
      if (value) {
        const { data, code } = await getList(params)
        if (code === 0) {
          this.couponList = data.list
        }
      }else{
        this.couponList = []
      }
    },

    onCouponSelect(){
      this.handlerSearch()
    },

    async defaultCoupon(id){
      if(id){
        const { data, code } = await getDetailById({
          id: id
        })
        if (code === 0) {
          this.couponList = [{
            id: id,
            name: data.name,
            name_interior: data.name_interior,
            reduce_cost: data.discount_amount,
          }]
        }
      }
    },

    handlerUserDetail(user_id){
      const newPage = this.$router.resolve({
        name: "memberDetail",
        query: { member_id: user_id },
      });
      window.open(newPage.href, "_blank");
    },

    handlerOrderDetail(order_no) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { order_no: order_no },
      });
      window.open(newPage.href, "_blank");
    },

  },
}
</script>

<style>
</style>